import { useState } from "react";
const LoginPage = () =>{

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
  
    const handleUsernameChange = (event : any) => {
      setUsername(event.target.value);
    };
  
    const handlePasswordChange = (event : any) => {
      setPassword(event.target.value);
    };
  
    const handleSubmit = (event : any) => {
      event.preventDefault();
  
      // 로그인 처리 로직을 여기에 추가 (예: API 호출, 상태 업데이트 등)
  
      // 로그인 성공 시 다음 동작을 수행하도록 코드를 추가할 수 있습니다.
      console.log('로그인 성공!');
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <label>
          ID:
          <input type="text" value={username} onChange={handleUsernameChange} />
        </label>
        <br />
        <label>
          Password:
          <input type="password" value={password} onChange={handlePasswordChange} />
        </label>
        <br />
        <button type="submit">로그인</button>
      </form>
    );
  };
      
export default LoginPage