import { useEffect, useRef, useState } from "react";
import { issuerList } from "./IssuerSelector";

interface Image {
    url : string
    name : string
    image : File
  }
   
interface SubmitButtonProps {
    selectedImages : Image[]
    selectedType : string
    originalPrice : string
    purchasedPrice : string
    salesPrice : string
    selectedDate: Date | null;
    purchaseManager : string
  }
  
const SubmitButton : React.FC<SubmitButtonProps> =({ 
    selectedImages,
    selectedType,
    originalPrice,
    purchasedPrice,
    salesPrice,
    selectedDate,
    purchaseManager

 }) => {
  const [resultImages, setResultImages] = useState([]);
    const [loading, setLoading] = useState(false)
    const [errored, setErrored] = useState(false);

    const [isModalOpen, setModalOpen] = useState(false);
    const modalBackground = useRef<HTMLDivElement | null>(null);

    const [errorMessage, setErrorMessage] = useState('');
  
    const handleButtonClick = (message:string) => {
      // 모달 열기 및 오류 메시지 설정
      setModalOpen(true);
      setErrorMessage(message);
    };
  
    const handleCloseModal = () => {
      // 모달 닫기
      setModalOpen(false);
    };
  


    
    let values = {
      type: selectedType,
      originalAmount: parseInt(originalPrice),
      purchaseAmount: parseInt(purchasedPrice),
      salesAmount: parseInt(salesPrice),
      purchasedAt: selectedDate,
      purchaseManager: purchaseManager
    }

    const formData = new FormData()

    const sendDataToBackend = async () => {
      console.log(values)
      if(!selectedType){
        alert("종류는 필수 입니다")
        return
      }
      if(!salesPrice){
        alert("판매 가격은 필수 입니다")
        return
      }
      setLoading(true)
    
      const timeoutDuration = 500000

      // 폼 데이터에 이미지 및 데이터 삽입
      try{
          for (let i = 0; i < selectedImages.length; i++) {
            formData.append(`file`, selectedImages[i].image)
          }
          formData.append('body', JSON.stringify(values))
      }
      catch(error:any){
        alert("전송 데이터 삽입 중 오류가 발생하였습니다.")
        console.error(error)
      }

      try{
        const response = await Promise.race([
          fetch(`${process.env.REACT_APP_FETCH_URL}/payments/create/eCouponsByGpt`, {
            method: 'POST',
            body: formData,
            mode : 'cors'
          }),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timed out')), timeoutDuration)
          ),
        ]);

        if(response instanceof Response && response.ok){
          const data = await response.json()
          console.log("dsa")
          console.log(data.result)
          console.log(data)
          if(data.result === "success"){
            alert('모든 재고가 정상적으로 재고화되었습니다.');
          }
          else if (data.result === "error"){
            setResultImages(data.data);
            alert('오류 재고가 발생하였습니다.');
            setErrored(true)
          }
        }
        else{
          alert("알 수 없는 오류가 발생하였습니다.")
        }
        setLoading(false)
    
      }
      catch(error : any){
        if (error.message === 'Request timed out'){
          console.error('Timeout Error:', error)
          alert('응답 시간이 초과되었습니다.');
        }
        else if(error.message === "Failed to fetch"){
          console.error(error)
          alert('이미지 전송에 실패하였습니다.');
        }
        else{
          console.error('Unexpected Error:', error);
          alert("알 수 없는 오류가 발생하였습니다.");          
        }
        setLoading(false)
      }
  };

    return (
      <div className="button-field">
          <button 
              className="button-send"
              onClick={sendDataToBackend}>
              <span className="button-text">판매하기 버튼</span>
          </button>
          { 
           errored ? (
            <div >
              <div>
              문제 재고 갯수 : {resultImages.length}
              </div>
              <div className="preview-image-list">
              {
              resultImages.map((image,index) => {
                return (
                  <div className="result-image-wrap">
                    <img src={image[2]} key={index} className="result-preview-image"></img>
                    {/* <div className="result-text">오류 {image[1]} </div> */}
                    <button className="error-message-button" onClick={()=>handleButtonClick(image[1])}>오류 내용 확인</button>
                </div>
                  );
                })
                
                }
                </div>
            </div>
          ) : (
              <div className="button-send-result">
            <span className="button-send-result-text">상태 메세지 생성 필드</span>
              </div>
          )}

      {isModalOpen && (
        <div className="modal-container">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <p>{errorMessage}</p>
          </div>
        </div>
      )}


        {
          // 로딩중일떄 모달창 띄어주기
          loading && <div>
              <div className={'modal-container'} ref={modalBackground} onClick={e => {
              if (e.target === modalBackground.current) {
                setModalOpen(false);
              }
            }}>
              <div className={'modal-content'}>
                <p>로딩중 </p>
              </div>
            </div>

          </div>
        }
    </div>
    )
}
export default SubmitButton