import React from 'react';

interface PurchasedPriceSelectorProps {
  purchasedPrice: string;
  onPurchasedPriceChange: (value: string) => void;
}

const PurchasedPriceSelector: React.FC<PurchasedPriceSelectorProps> = ({ purchasedPrice, onPurchasedPriceChange }) => {

  const handlePurchasedPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    onPurchasedPriceChange(event.target.value);
  };

  return (
    <div className='field'>
      <div className='field-text'>구매가 *</div>
      <input
        className='field-input'
        type="text"
        value={purchasedPrice}
        onChange={handlePurchasedPrice}
      />
    </div>
  );
};

export default PurchasedPriceSelector;
