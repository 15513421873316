import { useState } from "react";


interface purchaserSelectorProps {
    selectedPurchaser: string;
    setSelectedPurchaser : (newpurchaser: string) => void;
  }
  


const PurchaserSelector : React.FC<purchaserSelectorProps> =({ selectedPurchaser , setSelectedPurchaser }) => {


    const purchaserList = [
        '이준영',
        '김연준',
        '강인구',
        '김경태',
        '조유송',
        '테스트',
    ];

    const [selectedDomain, setSelectedDomain] = useState('');

    const handlePurchaserChange = (event : any) => {
        setSelectedPurchaser(event.target.value);
    };
    
    const handleDomainChange = (event :any) => {
        const selectedValue = event.target.value;
        if(selectedValue === '직접 입력'){
            setSelectedPurchaser('');
        }else{
            setSelectedPurchaser(selectedValue)
    
        }
        setSelectedDomain(selectedValue)
        };
    

    return (
        <div className="field">
            <div className="field-text">구매 담당자 *</div>
            <input
            className="field-input"
                id="Purchaser"
                type="text"
                value={selectedPurchaser}
                onChange={handlePurchaserChange} />
            <select
                className="field-dropdown"
                id="Purchaser-list"
                value={selectedPurchaser} onChange={handleDomainChange}>
                <option value="직접 입력"> 직접 입력</option>
                {purchaserList.map((domain, index) => (
                    <option key={index} value={domain}>
                        {domain}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default PurchaserSelector