// App.js (예시)
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LoginPage from './pages/Login';
import MainPage from './pages/Main';
import LoginFailPage from './pages/LoginFail';
const App = () => {
  // 이미지



  return (
    <div>

      <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/fail" element={<LoginFailPage />} />

      </Routes>
    </BrowserRouter>

    </div>
  );
};

export default App;