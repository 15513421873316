import React from 'react';

interface OriginalPriceSelectorProps {
  originalPrice: string; // Props 명칭을 camelCase로 변경
  onOriginalPriceChange: (value: string) => void;
}

const OriginalPriceSelector: React.FC<OriginalPriceSelectorProps> = ({ originalPrice, onOriginalPriceChange }) => {
    const handleOriginalPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        onOriginalPriceChange(event.target.value);
    };

    return (
        <div className='field'>
            <div className='field-text'>권면 금액 * </div>
            <input
                className='field-input'
                type="text"
                value={originalPrice}
                onChange={handleOriginalPrice}
            />
        </div>
    );
}

export default OriginalPriceSelector;
