import { useRef, useState } from "react";
import { json } from "stream/consumers";
import "../styles/App.scss"

interface Image {
  url : string
  name : string
  image : File
}

interface imagesSelectorProps {
  selectedImages : Image[]
  setSelectedImages : (newImages : Image[]) => void
  imageNameDict: string[]
  setImageNameDict :(newName : string[]) =>void
  selectedBrand : string
  setSelectedBrand : (newBrand : string) =>void
  needIssuer : boolean
  setNeedIssuer : (newIssuer : boolean) =>void

}

const ImagesSelector : React.FC<imagesSelectorProps> =({ 
  selectedImages , 
  setSelectedImages ,
  setSelectedBrand,
  selectedBrand,
  needIssuer,
  setNeedIssuer,

  imageNameDict,
  setImageNameDict
   }) => {
    let formData = new FormData();
    const timeoutDuration = 60000
    const issuerBrand = ["올리브영", "신세계백화점" , "CGV", "롯데시네마"]
    const [upLoading, setUpLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const modalBackground = useRef<HTMLDivElement | null>(null);





    const handleAddImages = async (event : any) => {
        let newImageLists :FileList = event.target.files;
        let imageLists = [...selectedImages];
        
        if(!imageLists.length && newImageLists){
          try{
          setUpLoading(true)
          const formData = new FormData()
          formData.append('file', newImageLists[0])
          const response = await Promise.race([
            fetch(`${process.env.REACT_APP_FETCH_URL}/payments/recognize-brand`, {
              method: 'POST',
              body: formData,
              mode: 'cors'
            }),
            new Promise((_, reject) =>
              setTimeout(() => reject(new Error('Request timed out')), timeoutDuration)
            ),
            ]);
              if (response instanceof Response && response.ok){
                setUpLoading(false)
              const data = await response.json()
              const brand = data.brand
                setSelectedBrand(brand)
                if (issuerBrand.includes(brand)){
                  console.log("테스트")
                  setNeedIssuer(true)
                }
              }
            }
            catch(e : any){
              if(e.message ==="Failed to fetch"){
                newImageLists = {
                  ...newImageLists, 
                  length: 0,
                };                
                alert("이미지 업로드 중 오류가 발생하였습니다.")
              }
              else if (e.message === "Request timed out"){
                alert("요청 시간이 초과되었습니다.")
              }
              setUpLoading(false)
            }
          }

        for (let i = 0; i < newImageLists.length; i++) {
          const currentImageUrl = URL.createObjectURL(newImageLists[i]);
          const currentImageName = newImageLists[i].name;
          const currentImage = newImageLists[i]
          console.log(imageNameDict)
          console.log(currentImageName)
          if(!imageNameDict.includes(currentImageName)){
            imageNameDict.push(currentImageName)
            formData.append('file', currentImage);
            imageLists.push({url : currentImageUrl, name :currentImageName, image:currentImage});
          }
          else{
            console.log("중복")
          }
    
        }
        setImageNameDict(imageNameDict)
        setSelectedImages(imageLists);
      };



    return (
      <div >
      <div className="file-selection">

        <div style={{
          marginBottom : 10
        }}>
        <label htmlFor="input-file" className="btn-upload">
          <span className="btn-upload-text"> 파일 선택 </span>
          </label>
        <span className="file-count"> 파일 {imageNameDict.length}개</span>

        <input 
        type="file" 
        name="file" 
        id="input-file" 
        multiple accept="image/*" 
        className="hide"
        onChange={handleAddImages}/>
        </div>


        {
          selectedImages.length>0 ? (
            <div className="preview-image-list">
            {selectedImages.map((image, id) => (
                <img key ={id}className="preview-image"
                  src={image.url}
                  alt={`${image.name}-${id}`}
                />
            ))}
            </div>
          ):(
            <div className="file-preview">
            <span className="file-preview-text"
            >상품 미리보기 필드</span> 
          </div>
  
          ) 
        }
        </div>
        {
          upLoading && 
          <div className={'modal-container'} ref={modalBackground} onClick={e => {
            if (e.target === modalBackground.current) {
              setModalOpen(false);
            }
          }}>
            <div className={'modal-content'}>
              <p>업로딩 중 </p>
            </div>
          </div>
  
        }
        

      </div>
    )
  }
export default ImagesSelector

  

{/* <div>
{selectedImages.map((image, id) => (
  <div key={id}>
    <img 
      className="preveiew-imgage"
      src={image.url}
      alt={`${image.name}-${id}`}
      style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }}
    />
    <p>{image.name}</p>
  </div>
))}
</div> */}
