import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DateSelectorProps {
    selectedDate: Date | null;
    setSelectedDate : (newDate: Date | null) => void;
  }
const DateSelector : React.FC<DateSelectorProps> =({ selectedDate , setSelectedDate }) => {
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    const [purchasedAt, setpurchasedAt] = useState(null)

  
    return (
      <div className="date-field">
        <div className='field-text'
        >구매 일자 *</div>
        <DatePicker
        className="datepicker"
        placeholderText="날짜를 선택하세요"
        dateFormat='yyyy-MM-dd' // 날짜 형태
        minDate={new Date('2000-01-01')} // minDate 이전 날짜 선택 불가
        maxDate={new Date()} // maxDate 이후 날짜 선택 불가
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
      ></DatePicker>
      </div>
    );
  }
export default DateSelector;