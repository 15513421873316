// ProductSelector.tsx

import React, { useEffect, useState } from 'react';

interface ProductSelectorProps {
  selectedBrand: string;
  selectedProduct : string;
  setSelectedProduct : (newProduct : string) => void

}

const ProductSelector: React.FC<ProductSelectorProps> = ({ 
  selectedBrand,
  selectedProduct,
  setSelectedProduct,
}) => {
  // 브랜드에 따라 다른 제품 리스트 설정

  // 브랜드 별 제품들 및 원래가격
  const productLists: Record<string, string[][]> = {
    스타벅스 : [
    ['카페 아메리카노 T','4500'], 
    ['카페라떼 T','5000'], 
    ['돌체콜드브루 T','6000']
    ],
    CU: [
    ['1000원권','1000'], 
    ['2000원권','2000'], 
    ['3000원권','3000'], 
    ['5000원권','5000'], 
    ['10000원권','10000'],
    ],
    GS25: [
    ['1000원권','1000'], 
    ['2000원권','2000'], 
    ['3000원권','3000'], 
    ['5000원권','5000'], 
    ['10000원권','10000'], 
    ],
    투썸플레이스: [
    ['아이스 아메리카노','4500'], 
    ['카페라떼 R','5000'], 
    ['아이스 카라멜 마끼아또 R','5900'], 
    ],
    메가커피: [
    ['HOT 아메리카노','1500'], 
    ['ICE 아메리카노','2000'], 
    ['스노우 샹그리아 에이드','3900'], 
    ],

    이디야커피: [
    ['ICED 아메리카노 L','3200'], 
    ['ICED 아메리카노 XL','4200'], 
    ['딸기라떼','3900'], 
    ],
    신세계백화점: [
      ['5000원권','5000'], 
      ['10000원권','10000'], 
      ['20000원권','20000'], 
      ['30000원권','30000'], 
      ['50000원권','50000'], 
      ['100000원권','100000'], 
    ],
    올리브영: [
      ['5000원권','5000'], 
      ['10000원권','10000'], 
      ['20000원권','20000'], 
      ['30000원권','30000'], 
      ['50000원권','50000'], 
      ['100000원권','100000'], 
    ],
    CGV: [
      ['2D 영화 예매권','15000'],
    ],
    롯데시네마: [
      ['2D 영화 예매권','15000'],
    ],
    // 다른 브랜드에 따른 제품 리스트 추가
  };

  const products = productLists[selectedBrand] || [];
  


  // Warning: You provided a `value` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultValue`. Otherwise, set either `onChange` or `readOnly`.


  const handledProductChange = (event : any) => {
    setSelectedProduct(event.target.value);
  };
  
    


  

  function handleDomainChange(event: any) {
    const selectedValue = event.target.value;

    if (selectedValue === '직접 입력') {
      setSelectedProduct('');
    } else {
      setSelectedProduct(selectedValue);
    }
    setSelectedProduct(selectedValue);
  }


  React.useEffect(() => {
    // 선택한 제품에 대한 정보
    const productInfo = productLists[selectedBrand]?.find((product) => product[0] === selectedProduct);
  
    if (productInfo) {
      const [productName, productPrice] = productInfo;
    } else {
    }
  }, [selectedBrand, selectedProduct]);
  

  return (
    <div className='field'>
      <div className='field-text'>상품명 * </div>
        <input
        className="field-input"
        id="Product-txt"
        type="text"
        value={selectedProduct}
        onChange={handledProductChange}
      />
      <select 
        className="field-dropdown" 
        id="Product-list" 
        value={selectedProduct} 
        onChange={handleDomainChange}
      >
      <option value="">직접 입력</option>
      {
        productLists[selectedBrand]?.map((produdct,index)=>(
          <option key={index} label={produdct[0]}>
            {produdct[0]}
          </option>
        ))
      }
      </select>
        <div>
        <br></br>
        <br></br>
          <div>
          </div>
      </div>
  </div>

  );
};

export default ProductSelector;
