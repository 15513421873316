import React, { useEffect, useState } from 'react';

interface IssuerSelectorProps {
    selectedIssuer : string
    setSelectedIssuer :(newIssuer : string) => void
    selectedBrand : string
    setSelectedBrand : (newBrand : string) => void
}

const issuerLists: Record<string, string[]> = {
    신세계백화점 : [
        "신세계백화점"
    ],
    올리브영 : [
        "올리브영"
    ],
    CGV :[
        "옥션 G마켓"
    ],
    롯데시네마 :[
        "롯데시네마"
    ],
    initial :[]
}


export const issuerList =["신세계백화점","올리브영","옥션 G마켓","롯데시네마"]
const IssuerSelector: React.FC<IssuerSelectorProps> = ({ 
    selectedIssuer ,
    setSelectedIssuer,
    selectedBrand ,
    setSelectedBrand
}) => {

    const handleSelectedIssuer = (event:any) =>{
        setSelectedIssuer(event.target.value)
    }


    return (
        <div>
            {
                <div className='field'>
                    <div className='field-text'>발행사 * </div>
                <input
                className='field-input'
                id="Issuer"
                type='text'
                value={selectedIssuer}
                onChange={handleSelectedIssuer}
                readOnly
                />
                <select 
                value={selectedIssuer} 
                onChange={handleSelectedIssuer}
                className='field-dropdown'
                >
                <option value="">직접 입력</option>
                {issuerLists[selectedBrand].map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
                ))}
                </select>
                </div>
            }
        </div>
    )
}
export default IssuerSelector