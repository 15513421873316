import React, { useState } from 'react';
import ProductSelector from './ProductSelector';

interface TypeSelectorProps {
    selectedType: string;
    setSelectedType: (newType: string) => void;
  }

const TypeSelector: React.FC<TypeSelectorProps> = ({ selectedType, setSelectedType }) => {

const [selectedDomain, setSelectedDomain] = useState('');

const TypeList = [ 
  '교환권',
  '금액권',
  '관람권',
  '영화관람권',
  '기프트콘',
  '모바일 관람권',
  '상품권',
];

  const handleTypeChange = (event :any) => {
    setSelectedType(event.target.value);
  };
  const handleDomainChange = (event :any) => {
    const selectedValue = event.target.value;
    if(selectedValue === '직접 입력'){
        setSelectedType('');
    }else{
      setSelectedType(selectedValue)

    }
    setSelectedDomain(selectedValue)
    };


  return (
    <div className='field'>
      <div className='field-text'>쿠폰 종류 * </div>
      <input
        className="field-input"
        id="Type-txt"
        type="text"
        value={selectedType}
        onChange={(e) => handleTypeChange(e)
        
        }
      />
      <select className="field-dropdown" id="Type-list" value={selectedType} onChange={handleDomainChange}>
        <option value="">직접 입력</option>
        {TypeList.map((domain, index) => (
          <option key={index} value={domain}>
            {domain}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TypeSelector;
