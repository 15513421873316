import { useState } from "react";
import BrandSelector from "../components/BrandSelector";
import DateSelector from "../components/DateSelector";
import ImagesSelector from "../components/ImagesSelector";
import IssuerSelector from "../components/IssuerSelector";
import ProductSelector from "../components/ProductSelector";
import SubmitButton from "../components/SubmitButton";
import TypeSelector from "../components/TypeSelector";
import SalesPriceSelector from "../components/SalesPriceSelector";
import OriginalPriceSelector from "../components/OriginalPriceSelector";
import PurchasedPriceSelector from "../components/PurchasedPriceSelector";
import PurchaserSelector from "../components/PurchaserSelector";
const MainPage =() =>{


  const [imageNameDict, setImageNameDict] = useState<string[]>([]);

    const [needIssuer, setNeedIssuer] = useState(false)

    const [selectedImages, setSelectedImages] = useState([])
    const handleImagesChange = (newImages : any) =>{
      setSelectedImages(newImages)
    }
    
    // 브랜드
    const [selectedBrand, setSelectedBrand] = useState('');
    const handleBrandChange = (newBrand : any) => {
      setSelectedBrand(newBrand);
    };
    // 타입
    const [selectedType, setSelectedType] = useState('');
    const handleTypeChange = (newType : any) => {
      setSelectedType(newType);
    };
    // 상품 
    const [selectedProduct, setSelectedProduct] = useState('');
  
    const [origianlPrice, setOriginalPrice] = useState('');
    const handleOriginalPriceChange = (newOriginalPrice : any) =>{
      setOriginalPrice(newOriginalPrice)
    }
    // const [salesPrice, setSalesPrice] = useState(''); 
    // const handleSalesPriceChange = (newSalesPrice : any) =>{
    //   setSalesPrice(newSalesPrice)
    // }
  
    // 날짜
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  
    // 구매자
    const [selecetedPurchaser , setselecetedPurchaser] = useState('')

  
    // 이슈
    const [selecetedIssuer , setSelectedIssuer] = useState('')


    // 구매 가격
    const [purchasedPrice, setPurchasedPrice] = useState('');

    const handlePurchasedPriceChange = (price :any) => {
      setPurchasedPrice(price);
    };

    // 판매 가격
    const [salesPrice, setSalesPrice] = useState('');

    const handleSalesPriceChange = (price :any) => {
      setSalesPrice(price);
    };


  

    // 구매금액 -> 지가 입력
    // 판매금액 -> 지가 입력
    // 원래금액 -> 우리가 입력해줌 

    return (
        <div className="whole-page">

          <section className="top-page">
            <div className="greeting">
              <div className="greeting-username">안녕하세요 username님</div>
              <div className="greeting-product">어떤 상품을 등록하시겠어요?</div>
            </div>
          </section>

          <section className="file-page">
            <ImagesSelector 
            imageNameDict ={imageNameDict}
            setImageNameDict = {setImageNameDict}
            selectedImages = {selectedImages} 
            setSelectedImages={handleImagesChange}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
            needIssuer ={needIssuer}
            setNeedIssuer ={setNeedIssuer}
            
            ></ImagesSelector>
          </section>

          <section className="middle-page">
            <TypeSelector selectedType={selectedType} setSelectedType={handleTypeChange}/>
            <ProductSelector selectedBrand={selectedBrand} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct}></ProductSelector>
            <OriginalPriceSelector
              originalPrice={origianlPrice}
              onOriginalPriceChange={handleOriginalPriceChange}
            ></OriginalPriceSelector>
            <PurchasedPriceSelector
              purchasedPrice={purchasedPrice}
              onPurchasedPriceChange={handlePurchasedPriceChange}
            ></PurchasedPriceSelector>

            <SalesPriceSelector
            salesPrice={salesPrice}
            onSalesPriceChange={handleSalesPriceChange}
            ></SalesPriceSelector>

            <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate}></DateSelector>
            <PurchaserSelector
            selectedPurchaser={selecetedPurchaser}
            setSelectedPurchaser={setselecetedPurchaser}
            ></PurchaserSelector>
            {
              needIssuer ? (
                <IssuerSelector
                selectedIssuer={selecetedIssuer}
                setSelectedIssuer={setSelectedIssuer}
                selectedBrand={selectedBrand}
                setSelectedBrand={setSelectedBrand}
                ></IssuerSelector>
              ) :
              (
                <div></div>
                )
            } 
          </section>
          <section className="bottom-page">
            <SubmitButton 
            selectedImages ={selectedImages}
            selectedType = {selectedType}
            originalPrice={origianlPrice}
            purchasedPrice={purchasedPrice}
            salesPrice={salesPrice}
            selectedDate={selectedDate}
            purchaseManager={selecetedPurchaser}
            
            ></SubmitButton> 
          </section>
            
      
      </div>


      );
    };
    
export default MainPage;