import React from 'react';

interface SalesPriceSelectorProps {
  salesPrice: string; // Props 명칭을 camelCase로 변경
  onSalesPriceChange: (value: string) => void;
}

// props를 매개변수로 받는 구조분해 할당을 사용합니다.
const SalesPriceSelector: React.FC<SalesPriceSelectorProps> = ({ salesPrice, onSalesPriceChange }) => {
    // 내부 상태 관리는 제거하고, props를 직접 사용합니다.

    const handleSalesPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSalesPriceChange(event.target.value);
    };

    return (
        <div className='field'>
            <div className='field-text'>판매가 * </div>
            <input
                className='field-input'
                type="text"
                value={salesPrice}
                onChange={handleSalesPrice}
            />
        </div>
    );
}

export default SalesPriceSelector;
